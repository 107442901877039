import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { auth } from '../services/firebase/firebaseConfigs';
import HotelsTable from '../components/hotels/hotelsTable';

function Dashboard() {
    const [hotels, setHotels] = useState([]);
    const [organization, setOrganization] = useState(null);
    const [loadingOrganization, setLoadingOrganization] = useState(true);
    const [loadingHotels, setLoadingHotels] = useState(true);
    const stateUser = useSelector((state) => state.user.user);
    const navigate = useNavigate();

    const fetchOrganization = useCallback(async () => {
        setLoadingOrganization(true);
        try {
            const token = await auth.currentUser.getIdToken(true);
            const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/organizations`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            setOrganization(data);
        } catch (error) {
            console.error('Error fetching organization:', error);
        } finally {
            setLoadingOrganization(false);
        }
    }, []);

    const fetchHotels = useCallback(async () => {
        setLoadingHotels(true);
        try {
            const token = await auth.currentUser.getIdToken(true);
            const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/hotels?organizationId=${stateUser?.organization?._id}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            setHotels(data);
        } catch (error) {
            console.error('Error fetching hotels:', error);
        } finally {
            setLoadingHotels(false);
        }
    }, [stateUser]);

    useEffect(() => {
        if (stateUser) {
            fetchOrganization();
            fetchHotels();
        }
    }, [stateUser, fetchOrganization, fetchHotels]);

    return (
        <div className="p-6 flex flex-col items-center bg-gray-100 min-h-screen">
            <div className="w-full max-w-4xl bg-white p-6 rounded-lg shadow-lg">
                <h1 className="text-4xl font-bold mb-6 text-center text-gray-800">
                    {loadingOrganization ? "Loading..." : (organization?.businessName || "Organization not registered.")}
                </h1>
                {loadingOrganization ? (
                    <div className="p-4 mb-6 text-center bg-green-100 border border-green-400 text-green-600 rounded-md">
                        Please wait ...
                    </div>
                ) : organization.isApproved ? (
                    <button
                        className="w-full py-3 mb-6 rounded bg-green-600 text-white font-semibold hover:bg-green-700 transition duration-200"
                        onClick={() => { navigate('/add-property') }}
                    >
                        Add Property
                    </button>
                ) : (
                    <div className="p-4 mb-6 text-center bg-yellow-100 border border-yellow-400 text-yellow-600 rounded-md">
                        Your Organization details are yet to be verified. Please get in touch with the <strong>Zeylon Life</strong> team.
                    </div>
                )}
                {loadingHotels ? (
                    <div className="p-4 text-center bg-green-100 border border-green-400 text-green-600 rounded-md">
                        Loading hotels...
                    </div>
                ) : (
                    <div className="w-full">
                        <HotelsTable hotels={hotels} />
                    </div>
                )}
            </div>
        </div>
    );
}

export default Dashboard;
