import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { auth } from '../services/firebase/firebaseConfigs';
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { useDispatch } from 'react-redux';
import { clearUserData, setUser } from '../state/user/actions';
import LoadingButtonComponent from '../components/loading/loader';
import PageLoader from '../components/loading/pageLoader';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [authStateLoading, setAuthStateLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      dispatch(clearUserData());
      await signInWithEmailAndPassword(auth, email, password);
      setSuccessMessage('Login successful! Redirecting...');
    } catch (error) {
      window.alert('Login failed: Invalid email or password');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        const token = await currentUser.getIdToken();
        const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/users`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const userData = await response.json();
        dispatch(setUser(userData));
        
        if (userData.role.name === 'super-admin') {
          navigate('/admin-dashboard');
        } else {
          navigate('/dashboard');
        }
      }
      setAuthStateLoading(false);
    });

    return () => unsubscribe();
  }, [navigate, dispatch]);

  return (
    authStateLoading ? <PageLoader /> :
      <div className="flex justify-center items-center min-h-screen bg-gradient-to-r from-green-400 to-blue-500">
        <form onSubmit={handleSubmit} className="flex flex-col gap-4 w-full max-w-md p-8 bg-white rounded-lg shadow-lg">
          <h1 className="text-center text-3xl font-bold mb-6 text-gray-800">Login</h1>
          {successMessage && (
            <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
              <strong className="font-bold">Success! </strong>
              <span className="block sm:inline">{successMessage}</span>
            </div>
          )}
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
          />
          <button
            type="submit"
            disabled={loading}
            className="w-full px-4 py-2 text-white bg-green-500 rounded-md hover:bg-green-600 focus:outline-none focus:bg-green-600 disabled:opacity-50"
          >
            {loading ? <LoadingButtonComponent /> : 'Login'}
          </button>
          <Link to="/register" className="mt-4 text-center text-green-700 hover:underline">Don't have an account? Register</Link>
          <Link to="/forget-password" className="mt-4 text-center text-green-700 hover:underline">Forget Password</Link>
        </form>
      </div>
  );
};

export default LoginPage;